/* Styling for the container and sections */
.login-container {
    display: flex;
    height: 100vh;
  }
  
  .left-section {
    flex: 1;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 2rem;
  }
  
  .left-section h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .left-section p {
    font-size: 1.2rem;
  }
  
  .right-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
  }
  
  .login-form {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login-form h2 {
    margin-bottom: 1.5rem;
  }
  
  .login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .login-button:hover {
    background-color: #218838;
  }
  